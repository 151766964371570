import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_LANG_STATE } from '../state/LangState';
import { AppState } from '../state/state';

const langSlice = createSlice({
    name: 'lang',
    initialState: INITIAL_LANG_STATE,
    reducers: {
        changeLanguageInProgress(state) {
            state.pending = true;
        },
        changeLanguageCompleted(state, action) {
            state.lang = action.payload;
            state.pending = false;
        },
        changeLanguageFailed(state, action) {
            state.error = action.payload;
            state.pending = false;
        },
    },
});

export const getLang = (state: AppState): string => {
    return state.lang ? state.lang.lang : 'en'; //default lang fallback
};

export const { changeLanguageInProgress, changeLanguageCompleted, changeLanguageFailed } = langSlice.actions;
export default langSlice.reducer;
