import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/root.scss';

import Home from './pages/Home';
import { Provider } from 'react-redux';
import 'typeface-roboto';
import './i18n/i18n';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Header from './components/sections/Header';
import Footer from './components/sections/Footer';
import { store } from './store/store';
import smoothscroll from 'smoothscroll-polyfill';

/**
 * -------------------
 *  DOM PROPERTIES
 * -------------------
 */
document.body.className = 'dark-version black-bg';

/**
 * -------------------
 *  INIT SCRIPTS
 * -------------------
 */
smoothscroll.polyfill();

/**
 * -------------------
 *  APP STRUCTURE
 * -------------------
 */

const routing = (
    <Provider store={store}>
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    </Provider>
);

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);
    root.render(routing);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
/*

<Provider store={store}>
        <Routes>
            <Header />
            <Route path="/" element={<Home />} />
            <Footer />
        </Routes>
    </Provider>

*/
