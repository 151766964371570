import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faMapMarker, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import meImagePng from '../../assets/images/hero.png';
import meImageWebp from '../../assets/images/hero.webp';
import { useTranslation } from 'react-i18next';
import coordsConfig from '../../config/CoordsConfig';
import './intro.scss';
const Intro = () => {
    const { t } = useTranslation();
    return (
        <section className="mh-home image-bg home-2-img" id="mh-home">
            <div className="img-foverlay img-color-overlay">
                <div className="container">
                    <div className="row section-separator sm-column-reverse vertical-middle-content home-padding">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-header-info">
                                <div className="mh-promo" data-aos="fade-up">
                                    <span>{t('Intro.Hello')}</span>
                                </div>

                                <h2 data-aos="fade-up">{coordsConfig.name}</h2>
                                <h3 data-aos="fade-up">{t('Intro.JobTitle')}</h3>

                                <ul>
                                    <li data-aos="fade-up">
                                        <a href={'mailto:' + coordsConfig.email}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            {coordsConfig.email}
                                        </a>
                                    </li>
                                    <li data-aos="fade-up">
                                        <a href={'callto:' + coordsConfig.phone}>
                                            <FontAwesomeIcon icon={faPhone} />
                                            {coordsConfig.phone}
                                        </a>
                                    </li>
                                    <li data-aos="fade-up">
                                        <FontAwesomeIcon icon={faMapMarker} />
                                        <address>
                                            {t('Location.City')}, {t('Location.Province')}
                                        </address>
                                    </li>
                                </ul>

                                <ul className="social-icon" data-aos="fade-up">
                                    <li>
                                        <a
                                            aria-label="linkedin"
                                            href={'https://linkedin.com/in/' + coordsConfig.linkedin}
                                            target="_blank"
                                        >
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            aria-label="github"
                                            href={'https://github.com/' + coordsConfig.github}
                                            target="_blank"
                                        >
                                            <FontAwesomeIcon icon={faGithub} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="hero-img" data-aos="fade-up">
                                <div className="img-border">
                                    <picture>
                                        <source srcSet={meImageWebp} type="image/webp" />
                                        <source srcSet={meImagePng} type="image/png" />
                                        <img src={meImagePng} alt="" className="img-fluid" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Intro;
