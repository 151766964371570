import i18n from '../../i18n/i18n';
import { Dispatch, AnyAction } from 'redux';
import { changeLanguageCompleted, changeLanguageFailed, changeLanguageInProgress } from '../slices/langSlice';

const changeLang =
    (lang: string) =>
    (dispatch: Dispatch<AnyAction>): void => {
        dispatch(changeLanguageInProgress());
        i18n.changeLanguage(lang, (err) => {
            if (err) {
                dispatch(changeLanguageCompleted(err));
            } else {
                dispatch(changeLanguageFailed(lang));
            }
        });
    };

export default changeLang;
