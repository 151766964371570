import { useTranslation } from 'react-i18next';
import changeLang from '../../store/services/langService';
import { connect } from 'react-redux';
import { toggleSidebar, toggleScrollNav } from '../../store/services/NavbarService';
import { Link } from 'react-scroll';
import { Dispatch } from 'redux';
import { AppState } from '../../store/state/state';
import { useEffect } from 'react';
import { getLang } from '../../store/slices/langSlice';
import { getScrollNavState, getSidebarState } from '../../store/slices/navbarSlice';

export interface Props {
    lang: string;
    sideNav: boolean;
    scrollNav: boolean;
    // eslint-disable-next-line
    dispatch: Dispatch<any>;
}

const windowYPosScrollHeaderTrigger = 50;
let isScrollHeaderActive = window.pageYOffset > windowYPosScrollHeaderTrigger;

// eslint-disable-next-line
const scrollListener = (dispatch: Dispatch<any>) => {
    const activateScollHeader = !isScrollHeaderActive && window.pageYOffset > windowYPosScrollHeaderTrigger;
    const disableScollHeader = isScrollHeaderActive && window.pageYOffset <= windowYPosScrollHeaderTrigger;

    if (activateScollHeader || disableScollHeader) {
        isScrollHeaderActive = !isScrollHeaderActive; //toggle
        dispatch(toggleScrollNav(isScrollHeaderActive));
    }
};

// eslint-disable-next-line
const combineToggleSidebarAndLangSwitch = (sideNav: boolean, lang: string, dispatch: Dispatch<any>) => {
    dispatch(toggleSidebar(sideNav));
    dispatch(changeLang(lang.substring(0, 2).toLowerCase()));
};

const Header = ({ sideNav, scrollNav, dispatch }: Props) => {
    useEffect(() => {
        window.addEventListener('scroll', function () {
            scrollListener(dispatch);
        });
        return () => {
            window.removeEventListener('scroll', function () {
                scrollListener(dispatch);
            });
        };
    });

    const { t } = useTranslation();
    return (
        <header
            className={'black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav ' + (scrollNav ? 'nav-strict' : '')}
            id="mh-header"
        >
            <div className={'overlay ' + (sideNav ? 'active' : '')} onClick={() => dispatch(toggleSidebar(false))} />
            <div className="container">
                <div className="row">
                    <nav className="navbar navbar-expand-lg mh-nav nav-btn">
                        <button
                            className={'navbar-toggler ' + (sideNav ? 'active' : '')}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => dispatch(toggleSidebar(!sideNav))}
                        >
                            <span className="navbar-toggler-icon icon" />
                        </button>

                        <div
                            className={'collapse navbar-collapse ' + (sideNav ? 'active' : '')}
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav mr-auto ml-auto">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-home"
                                        spy={true}
                                        offset={-150}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.Home')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-about"
                                        spy={true}
                                        offset={-150}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.About')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-skills"
                                        spy={true}
                                        offset={-150}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.Skills')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-experience"
                                        offset={-150}
                                        spy={true}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.Experiences')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-featured-project"
                                        spy={true}
                                        offset={-150}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.Projects')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className="nav-link"
                                        activeClass="active"
                                        to="mh-contact"
                                        spy={true}
                                        offset={-200}
                                        onClick={() => dispatch(toggleSidebar(false))}
                                    >
                                        {t('Navigation.Contact')}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link"
                                        onClick={() =>
                                            combineToggleSidebarAndLangSwitch(
                                                false,
                                                t('Navigation.ChangeLang'),
                                                dispatch,
                                            )
                                        }
                                    >
                                        {t('Navigation.ChangeLang')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

const mapStateToProps = (state: AppState) => ({
    lang: getLang(state),
    sideNav: getSidebarState(state),
    scrollNav: getScrollNavState(state),
});

export default connect(mapStateToProps)(Header);
