export interface SkillsConfig {
    [key: string]: number;
}

const skillsConfig: SkillsConfig = {
    TypeScript: 95,
    'C#': 95,
    SQL: 90,
    PowerShell: 80,
    Java: 75,
    Python: 55,
    'C / C++': 35,
};

export default skillsConfig;
