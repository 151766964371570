import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_NAVBAR_STATE } from '../state/NavbarState';
import { AppState } from '../state/state';

const navBarSlice = createSlice({
    name: 'lang',
    initialState: INITIAL_NAVBAR_STATE,
    reducers: {
        sidebarActivationSuccess(state, action) {
            state.sidebarActivated = action.payload;
        },
        scrollnavActivationSuccess(state, action) {
            state.scrollNavActivated = action.payload;
        },
    },
});

export const getSidebarState = (state: AppState): boolean => {
    return state.navbar && state.navbar.sidebarActivated;
};

export const getScrollNavState = (state: AppState): boolean => {
    return state.navbar && state.navbar.scrollNavActivated;
};

export const { sidebarActivationSuccess, scrollnavActivationSuccess } = navBarSlice.actions;
export default navBarSlice.reducer;
