import LazyLoad from 'react-lazyload';

export interface ProjectValues {
    png: string;
    webp: string;
    tag: string;
    name: string;
    subname: string;
    infos: string;
}

const Project = (props: ProjectValues) => {
    return (
        <div className="col-sm-12 col-md-12 mh-featured-item">
            <div className="row">
                <div className="col-sm-12 col-md-7">
                    <div className="mh-featured-project-img shadow-2" data-aos="fade-up">
                        <LazyLoad once>
                            <picture>
                                <source srcSet={props.webp} type="image/webp" />
                                <source srcSet={props.png} type="image/png" />
                                <img src={props.png} alt="" className="img-fluid" />
                            </picture>
                        </LazyLoad>
                    </div>
                </div>
                <div className="col-sm-12 col-md-5">
                    <div className="mh-featured-project-content">
                        <h4 className="project-category" data-aos="fade-up">
                            {props.tag}
                        </h4>
                        <h2 data-aos="fade-up">{props.name}</h2>
                        <span data-aos="fade-up">{props.subname}</span>
                        <p data-aos="fade-up">{props.infos}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Project;
