export interface EducationProps {
    eduYear: string;
    title: string;
    school: string;
    schoolURL: string;
    moreInfos: Array<string>;
}

const Education = (props: EducationProps) => {
    return (
        <div className="mh-education-item dark-bg" data-aos="fade-up">
            <h4>
                {props.title}
                <a href={props.schoolURL}>{props.school}</a>
            </h4>
            <div className="mh-eduyear">{props.eduYear}</div>
            <ul>
                {props.moreInfos.map((value, idx) => (
                    <li key={'education_' + props.eduYear + '_info' + idx}>{value}</li>
                ))}
            </ul>
        </div>
    );
};

export default Education;
