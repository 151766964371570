export interface LangState {
    lang: string;
    pending: boolean;
    error: string;
}

export const INITIAL_LANG_STATE: LangState = {
    lang: 'en',
    pending: false,
    error: '',
};
