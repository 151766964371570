import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface InterestProps {
    fontAwesomeIcon: IconProp;
    title: string;
    infos: string;
}

const Interest = (props: InterestProps) => {
    return (
        <div className="col-sm-12 col-md-4 mb-4">
            <div className="mh-service-item shadow-1 dark-bg" data-aos="fade-up">
                <FontAwesomeIcon icon={props.fontAwesomeIcon} className="main-color" />
                <h3>{props.title}</h3>
                <p>{props.infos}</p>
            </div>
        </div>
    );
};

export default Interest;
