import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import coordsConfig from '../../config/CoordsConfig';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="mh-footer mh-footer-3" id="mh-contact">
            <div className="container-fluid map-image">
                <div className="row section-separator">
                    <div className="col-sm-12 section-title" data-aos="fade-up">
                        <h3>{t('Contact.ContactMe')}</h3>
                    </div>
                    <div className="image-bg col-sm-12">
                        <div className="container mt-30">
                            <div className="row">
                                <div className="col-sm-12 col-md-6 mh-footer-address">
                                    <div className="col-sm-12 xs-no-padding">
                                        <div
                                            className="mh-address-footer-item row g-0 dark-bg shadow-1"
                                            data-aos="fade-up"
                                        >
                                            <div className="each-icon col-md-3 col-sm-3">
                                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                                            </div>
                                            <div className="each-info col-md-8 col-sm-8">
                                                <h4>{t('Contact.Email')}</h4>
                                                <a href={'mailto:' + coordsConfig.email}>{coordsConfig.email}</a>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 mh-footer-address" data-aos="fade-up">
                                    <div className="col-sm-12 xs-no-padding">
                                        <div
                                            className="mh-address-footer-item row g-0 dark-bg shadow-1"
                                            data-aos="fade-up"
                                        >
                                            <div className="each-icon col-md-3 col-sm-3">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </div>
                                            <div className="each-info col-md-8 col-sm-8">
                                                <h4>{t('Contact.Phone')}</h4>
                                                <a href={'callto:' + coordsConfig.phone}>{coordsConfig.phone}</a>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="text-left text-xs-center">
                                                <p>
                                                    {t('Copyright')} &copy; 2019-{new Date().getFullYear()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <ul className="social-icon">
                                                <li>
                                                    <a
                                                        aria-label="linkedin"
                                                        href={'https://linkedin.com/in/' + coordsConfig.linkedin}
                                                        target="blank_"
                                                    >
                                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        aria-label="github"
                                                        href={'https://github.com/' + coordsConfig.github}
                                                        target="blank_"
                                                    >
                                                        <FontAwesomeIcon icon={faGithub} />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
