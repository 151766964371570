export interface CoordsConfig {
    name: string;
    email: string;
    phone: string;
    linkedin: string;
    github: string;
}

const coordsConfig: CoordsConfig = {
    name: 'Adam Michaud',
    email: 'adam@adammichaud.com',
    phone: '+1 514-825-3223',
    linkedin: 'michaudadam',
    github: 'unatiks',
};

export default coordsConfig;
