import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { scrollnavActivationSuccess, sidebarActivationSuccess } from '../slices/navbarSlice';

export const toggleSidebar =
    (activated: boolean) =>
    (dispatch: Dispatch<AnyAction>): void => {
        dispatch(sidebarActivationSuccess(activated));
    };

export const toggleScrollNav =
    (activated: boolean) =>
    (dispatch: Dispatch<AnyAction>): void => {
        dispatch(scrollnavActivationSuccess(activated));
    };
