import { connect } from 'react-redux';
import About from '../components/sections/About';
import Experiences from '../components/sections/Experiences';
import Intro from '../components/sections/Intro';
import Projects from '../components/sections/Projects';
import Interests from '../components/sections/Interests';
import Skills from '../components/sections/Skills';
import WorkTogether from '../components/sections/WorkTogether';
import LazyLoad from 'react-lazyload';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    useEffect(() => {
        AOS.init({
            useClassNames: true,
            delay: 200,
        });
        AOS.refresh();
    });

    return (
        <div>
            <LazyLoad>
                <Intro />
            </LazyLoad>
            <LazyLoad>
                <About />
            </LazyLoad>
            <LazyLoad>
                <Interests />
            </LazyLoad>
            <LazyLoad>
                <Skills />
            </LazyLoad>
            <LazyLoad>
                <Experiences />
            </LazyLoad>
            <LazyLoad>
                <Projects />
            </LazyLoad>
            <LazyLoad>
                <WorkTogether />
            </LazyLoad>
        </div>
    );
};

export default connect()(Home);
