import { configureStore } from '@reduxjs/toolkit';
import langSlice from './slices/langSlice';
import navbarSlice from './slices/navbarSlice';

export const store = configureStore({
    reducer: {
        lang: langSlice,
        navbar: navbarSlice,
    },
});
