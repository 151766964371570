import { useTranslation } from 'react-i18next';
import Skill from '../Skill';
import SkillsConfig from '../../config/SkillsConfig';
import './skills.scss';

function showSkills() {
    const skills = [];
    for (const key in SkillsConfig) {
        skills.push(<Skill key={'_' + key} name={key} percentage={SkillsConfig[key]} />);
    }
    return skills;
}

const Skills = () => {
    const { t } = useTranslation();

    return (
        <section className="mh-skills" id="mh-skills">
            <div className="home-v-img">
                <div className="container">
                    <div className="row section-separator">
                        <div className="section-title text-center col-sm-12" />
                        <div className="col-sm-12 col-md-6 mx-auto">
                            <div className="mh-skills-inner">
                                <div className="mh-professional-skill" data-aos="fade-up">
                                    <h3>{t('TechnicalSkills')}</h3>
                                    <div className="each-skills">{showSkills()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Skills;
