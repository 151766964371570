import { ResourceLanguage } from 'i18next';

const en: ResourceLanguage = {
    translation: {
        Copyright: 'All rights reserved - Adam Michaud',
        MoreDetails: 'more details',
        TechnicalSkills: 'Technical skills',

        Intro: {
            Hello: 'Hello, I am',
            JobTitle: 'Tech Lead / Architect, M.Eng',
        },

        Contact: {
            ContactMe: 'Contact me',
            Email: 'Email',
            Phone: 'Phone',
        },

        Navigation: {
            Home: 'Home',
            About: 'About me',
            Skills: 'Skills',
            Experiences: 'Experiences',
            Projects: 'Projects',
            Contact: 'Contact me',
            ChangeLang: 'Français',
        },

        AboutMe: {
            Title: 'About me',
            Infos: "Hello, I'm Adam, tech Lead / architect in Montreal. I love programming, software design, computer security, algorithms and much more ! My ease of learning allows me to adapt to any situation. I also have a great ability to solve problems and develop suitable solutions for a multitude of applications. I can create solutions using these languages:",
        },

        Interests: {
            Title: 'My fields of interest',
            Programming: {
                Title: 'Applications',
                Infos: 'Designing software and web or mobile applications allows me to help companies improve their productivity and achieve their digital goals.',
            },
            ArtificialIntelligence: {
                Title: 'Artificial intelligence',
                Infos: 'AI makes it possible to tackle very complex problems and shapes our vision of the world for the future. I am mainly interested in the development of solutions based on machine learning.',
            },
            VideoGames: {
                Title: 'Video games',
                Infos: 'This computer application interests me for the diversity of the work to be accomplished (animations, artificial intelligence, graphics, physical programming, software engineering, databases...).',
            },
        },

        Education: {
            Title: ' Education',
            Master: {
                Degree: 'M.Eng in Computer engineering',
                School: 'Polytechnique Montreal',
            },

            Bachelor: {
                Degree: 'B.Sc. Computer science',
                School: 'University of Montreal',
                Scholarship: 'Excellence Scholarship from DIRO Graduates and Professors',
                Mention: 'Mention of academic excellence',
            },

            College: {
                Degree: 'DEC Computer science and mathematics',
                School: 'Lionel-Groulx College',
                RScore: 'R Score',
            },
        },

        WorkExperiences: {
            Title: ' Work experiences',
            CDPQ: {
                Title: 'Developer intern',
                Dates: 'January 2021 - April 2021',
                Dept: 'Digital experience',
                Tasks: {
                    Task1: 'Propose, conceptualize and develop efficient solutions to deliver self-service digital platforms.',
                    Task2: 'Develop responsive user interfaces in order to improve the user experience.',
                    Task3: 'Develop and configure analytics collection systems and data visualization dashboards based on Google Analytics technologies.',
                },
            },
            JovacoProgrammer: {
                Title: 'Programmer-Analyst',
                Dates: 'May 2021 - Today',
                Dept: 'Cloud solutions',
                Tasks: {
                    Task1: 'Participate in the design and architecture choices of modern web applications deployed on the cloud.',
                    Task2: 'Maintain a large legacy codebase.',
                    Task3: 'Full-stack coding of web applications using in particular Angular and C#.',
                    Task4: 'Create graphical interfaces focused on usability and user experience using the Figma design tool.',
                    Task5: 'Automate tasks (CI/CD) in Azure DevOps for efficiency and quality assurance purposes.',
                },
            },
            JovacoTechLead: {
                Title: 'Tech Lead / Architect',
                Dates: 'January 2024 - Today',
                Dept: 'Cloud solutions',
                Tasks: {
                    Task1: 'Oversee technical developers and team members, including code reviews and programming.',
                    Task2: 'Define and validate technical needs.',
                    Task3: 'Participate in product development, writing code, creating automated tests, and defining coding standards.',
                    Task4: 'Research and improve work methods, such as requirement breakdown and programming.',
                },
            },
        },

        Projects: {
            Title: 'Some projects',
            Project1: {
                Tag: 'Web Programming',
                Name: 'Mustangs',
                Subname: 'Laravel',
                Infos: "The website was designed using the Laravel framework in order to meet the digital needs of the sports team. From the needs analysis to the web design, no detail was overlooked so that the team could take advantage of the website's presence.",
            },
            Project2: {
                Tag: 'Machine Learning',
                Name: 'Music Classification',
                Subname: 'Design & Development',
                Infos: 'As part of the machine learning course at the École de technologie supérieure (GTI770), I developed a music classification system using the Python language and the Scikit-Learn and TensorFlow libraries.',
            },
            Project3: {
                Tag: 'Software conception',
                Name: 'Travel Reservations',
                Subname: 'Software engineering',
                Infos: 'The goal of this project was to apply all the concepts of software engineering. From design by diagrams to coding using design patterns in the JAVA language.',
            },
            Project4: {
                Tag: 'Video games',
                Name: 'Intelligent agents',
                Subname: 'Artificial intelligence',
                Infos: "Using the Unreal 4 engine, it was a question of implementing the agents' intelligent systems. Among these systems are navigation, decision-making systems and even animations.",
            },
            Project5: {
                Tag: 'Video games',
                Name: 'Polarize',
                Subname: 'C# and XNA',
                Infos: 'Polarize is a multiplayer arena game coded in C# with the XNA game engine. The project took place in 2017 over a period of four months in a team of three as part of my final college studies project. The objective was to consolidate learning in programming, mathematics, physics and to extend our knowledge in software development.',
            },
        },

        WorkTogether: {
            Title: "Let's work together",
            Infos: 'Are you looking for the perfect candidate for your next ambitious project? I am always motivated to take up challenges and participate in the creation of new digital ideas. Feel free to contact me to discuss how I can help you turn your idea into reality.',
        },

        Location: {
            City: 'Montreal',
            Province: 'Quebec',
        },
    },
};

export default en;
