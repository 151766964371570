export interface WorkExperienceProps {
    title: string;
    companyName: string;
    department: string;
    companyURL: string;
    workYears: string;
    tasks: Array<string>;
    technologies: string[];
}

function showTechStack(technologies: string[]) {
    const techStack = [];
    for (const i in technologies) {
        techStack.push(
            <li key={'tech_' + technologies[i]}>
                <span>{technologies[i]}</span>
            </li>,
        );
    }
    return techStack;
}

const WorkExperience = (props: WorkExperienceProps) => {
    return (
        <div className="mh-work-item dark-bg" data-aos="fade-up">
            <h4>
                {props.title}
                <a href={props.companyURL}> {props.companyName}</a>
            </h4>
            <div className="work-department">{props.department}</div>
            <div className="mh-eduyear">{props.workYears}</div>
            <ul>
                {props.tasks.map((value, idx) => (
                    <li key={'work_' + props.workYears + '_task' + idx}>
                        {value}
                        <br />
                        <br />
                    </li>
                ))}
            </ul>
            <div className="mh-about-tag" data-aos="fade-up">
                <ul>{showTechStack(props.technologies)}</ul>
            </div>
        </div>
    );
};

export default WorkExperience;
