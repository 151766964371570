import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Education from '../Education';
import WorkExperience from '../WorkExperience';
import './experiences.scss';

const Experiences: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <section className="mh-experince image-bg featured-img-one" id="mh-experience">
            <div className="img-color-overlay">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-education">
                                <h3 data-aos="fade-up">
                                    <FontAwesomeIcon icon={faGraduationCap} />
                                    {t('Education.Title')}
                                </h3>
                                <div className="mh-education-details">
                                    <Education
                                        eduYear="2019-2021"
                                        title={t('Education.Master.Degree')}
                                        school={t('Education.Master.School')}
                                        schoolURL="https://www.polymtl.ca/"
                                        moreInfos={['GPA: 3.960 / 4.000']}
                                    />
                                    <Education
                                        eduYear="2017-2019"
                                        title={t('Education.Bachelor.Degree')}
                                        school={t('Education.Bachelor.School')}
                                        schoolURL="https://www.umontreal.ca/"
                                        moreInfos={[
                                            'GPA: 3.954 / 4.300',
                                            t('Education.Bachelor.Mention'),
                                            t('Education.Bachelor.Scholarship'),
                                        ]}
                                    />
                                    <Education
                                        eduYear="2015-2017"
                                        title={t('Education.College.Degree')}
                                        school={t('Education.College.School')}
                                        schoolURL="https://www.clg.qc.ca/"
                                        moreInfos={[t('Education.College.RScore') + ': 32,124']}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-work">
                                <h3>
                                    <FontAwesomeIcon icon={faBriefcase} />
                                    {t('WorkExperiences.Title')}
                                </h3>
                                <div className="mh-experience-details">
                                    <WorkExperience
                                        title={t('WorkExperiences.JovacoTechLead.Title')}
                                        companyName="JOVACO"
                                        department={t('WorkExperiences.JovacoTechLead.Dept')}
                                        companyURL="https://jovaco.ca"
                                        workYears={t('WorkExperiences.JovacoTechLead.Dates')}
                                        tasks={[
                                            t('WorkExperiences.JovacoTechLead.Tasks.Task1'),
                                            t('WorkExperiences.JovacoTechLead.Tasks.Task2'),
                                            t('WorkExperiences.JovacoTechLead.Tasks.Task3'),
                                            t('WorkExperiences.JovacoTechLead.Tasks.Task4'),
                                        ]}
                                        technologies={[
                                            'Devops',
                                            'Angular',
                                            'C#',
                                            'Azure',
                                            'TypeScript',
                                            'SCSS',
                                            'Agile',
                                        ]}
                                    />

                                    <WorkExperience
                                        title={t('WorkExperiences.JovacoProgrammer.Title')}
                                        companyName="JOVACO"
                                        department={t('WorkExperiences.JovacoProgrammer.Dept')}
                                        companyURL="https://jovaco.ca"
                                        workYears={t('WorkExperiences.JovacoProgrammer.Dates')}
                                        tasks={[
                                            t('WorkExperiences.JovacoProgrammer.Tasks.Task1'),
                                            t('WorkExperiences.JovacoProgrammer.Tasks.Task2'),
                                            t('WorkExperiences.JovacoProgrammer.Tasks.Task3'),
                                            t('WorkExperiences.JovacoProgrammer.Tasks.Task4'),
                                            t('WorkExperiences.JovacoProgrammer.Tasks.Task5'),
                                        ]}
                                        technologies={[
                                            'Devops',
                                            'Angular',
                                            'C#',
                                            'Azure',
                                            'TypeScript',
                                            'SCSS',
                                            'Agile',
                                        ]}
                                    />
                                    <WorkExperience
                                        title={t('WorkExperiences.CDPQ.Title')}
                                        companyName="Caisse de dépôt et placement du Québec"
                                        department={t('WorkExperiences.CDPQ.Dept')}
                                        companyURL="https://cdpq.com"
                                        workYears={t('WorkExperiences.CDPQ.Dates')}
                                        tasks={[
                                            t('WorkExperiences.CDPQ.Tasks.Task1'),
                                            t('WorkExperiences.CDPQ.Tasks.Task2'),
                                            t('WorkExperiences.CDPQ.Tasks.Task3'),
                                        ]}
                                        technologies={['React', 'PowerShell', 'C#', 'TypeScript', 'CSS', 'Agile']}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experiences;
