import aboutImagePng from '../../assets/images/station.png';
import aboutImageWebp from '../../assets/images/station.webp';
import { useTranslation } from 'react-i18next';
import skillsConfig from '../../config/SkillsConfig';
import './about.scss';

function showSkillTags() {
    const tags = [];
    for (const skill in skillsConfig) {
        tags.push(
            <li key={'tag_' + skill}>
                <span>{skill}</span>
            </li>,
        );
    }
    return tags;
}

const About = () => {
    const { t } = useTranslation();
    return (
        <section className="mh-about aboutme" id="mh-about">
            <div className="container">
                <div className="row section-separator">
                    <div className="col-sm-12 col-md-6 aboutme-image-container">
                        <picture data-aos="fade-up">
                            <source srcSet={aboutImageWebp} type="image/webp" />
                            <source srcSet={aboutImagePng} type="image/png" />
                            <img src={aboutImagePng} alt="" className="img-fluid" />
                        </picture>
                    </div>
                    <div className="col-sm-12 col-md-6 infos">
                        <div className="mh-about-inner">
                            <h2 data-aos="fade-up">{t('AboutMe.Title')}</h2>
                            <p data-aos="fade-up">{t('AboutMe.Infos')}</p>
                            <div className="mh-about-tag" data-aos="fade-up">
                                <ul>{showSkillTags()}</ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
