import { faRobot, faCode, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Interest from '../Interest';
import './interests.scss';

const Interests = () => {
    const { t } = useTranslation();
    return (
        <section className="mh-service">
            <div className="container-lg">
                <div className="row section-separator">
                    <div className="col-sm-12 text-center section-title" data-aos="fade-up">
                        <h2>{t('Interests.Title')}</h2>
                    </div>
                    <Interest
                        fontAwesomeIcon={faCode}
                        title={t('Interests.Programming.Title')}
                        infos={t('Interests.Programming.Infos')}
                    />
                    <Interest
                        fontAwesomeIcon={faRobot}
                        title={t('Interests.ArtificialIntelligence.Title')}
                        infos={t('Interests.ArtificialIntelligence.Infos')}
                    />
                    <Interest
                        fontAwesomeIcon={faGamepad}
                        title={t('Interests.VideoGames.Title')}
                        infos={t('Interests.VideoGames.Infos')}
                    />
                </div>
            </div>
        </section>
    );
};

export default Interests;
