import { useTranslation } from 'react-i18next';
import './worktogether.scss';

const WorkTogether = () => {
    const { t } = useTranslation();
    return (
        <section className="mh-quotes image-bg home-2-img">
            <div className="img-color-overlay">
                <div className="container">
                    <div className="row section-separator">
                        <div className="each-quotes col-sm-12 col-md-6">
                            <h3 data-aos="fade-up">{t('WorkTogether.Title')}</h3>
                            <p data-aos="fade-up">{t('WorkTogether.Infos')}</p>
                            <a href="#mh-contact" className="btn btn-fill" data-aos="fade-up">
                                {t('Contact.ContactMe')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WorkTogether;
