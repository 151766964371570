import { ResourceLanguage } from 'i18next';

const fr: ResourceLanguage = {
    translation: {
        MoreDetails: 'plus de détails',
        Copyright: 'Tous droits réservés - Adam Michaud',
        TechnicalSkills: 'Compétences techniques',

        Intro: {
            Hello: 'Bonjour, je suis',
            JobTitle: 'Lead Technique / Architecte, M.Ing',
        },

        Contact: {
            ContactMe: 'Contactez-moi',
            Email: 'Courriel',
            Phone: 'Téléphone',
        },

        Navigation: {
            Home: 'Accueil',
            About: 'À propos',
            Skills: 'Compétences',
            Experiences: 'Expériences',
            Projects: 'Projets',
            Contact: 'Contact',
            ChangeLang: 'English',
        },

        AboutMe: {
            Title: 'À propos de moi',
            Infos: "Bonjour, je suis Adam, lead technique / architecte à Montréal. J'aime la programmation, la conception de logiciels, la sécurité informatique, l'apprentissage machine, les bases de données, l'algorithmie et plus encore ! Ma facilité d'apprentissage me permet de m'adapter à n'importe quelle situation. J'ai aussi une grande capacité à résoudre des problèmes et à développer des solutions adaptées pour une multitude d'applications de l'informatique. Je peux notamment créer des solutions à l'aide de ces langages:",
        },

        Interests: {
            Title: "Mes champs d'intérêt",
            Programming: {
                Title: 'Applications',
                Infos: "Concevoir des logiciels et des applications webs ou mobiles me permet d'aider les entreprises à améliorer leur productivité et à atteindre leurs objectifs numériques.",
            },
            ArtificialIntelligence: {
                Title: 'Intelligence artificielle',
                Infos: "L'IA permet de s'attaquer à des problèmes très complexes et façonne notre vision du monde pour le futur. Je m'intéresse surtout au développement de solutions basées sur l'apprentissage machine.",
            },
            VideoGames: {
                Title: 'Jeux vidéos',
                Infos: "Cette application de l'informatique m'intéresse pour la diversité du travail à accomplir (animations, intelligence artificielle, graphismes, programmation physique, génie logiciel, bases de données, etc).",
            },
        },

        Education: {
            Title: ' Éducation',
            Master: {
                Degree: 'M.Ing Génie Informatique',
                School: 'Polytechnique Montréal',
            },

            Bachelor: {
                Degree: 'B.Sc. Informatique',
                School: 'Université de Montréal',
                Scholarship: "Bourse d'Excellence des Diplômés et Professeurs du DIRO",
                Mention: "Mentions d'excellence académique",
            },

            College: {
                Degree: 'DEC Sciences Informatiques et Mathématiques',
                School: 'Collège Lionel-Groulx',
                RScore: 'Cote R',
            },
        },

        WorkExperiences: {
            Title: ' Expérience de travail',
            CDPQ: {
                Title: 'Stagiaire développeur',
                Dates: 'Janvier 2021 - Avril 2021',
                Dept: 'Expérience numérique',
                Tasks: {
                    Task1: 'Proposer, conceptualiser et développer des solutions efficientes pour livrer des plateformes numériques libre-service.',
                    Task2: 'Développer des interfaces utilisateurs réactives dans le but d’améliorer l’expérience des utilisateurs.',
                    Task3: 'Développer et configurer des systèmes de collection de données et des tableaux de bord basé sur la technologie de Google Analytics.',
                },
            },
            JovacoProgrammer: {
                Title: 'Programmeur-Analyste',
                Dates: "Mai 2021 - Aujourd'hui",
                Dept: 'Solutions infonuagiques',
                Tasks: {
                    Task1: 'Participer à la conception de nouvelles applications web modernes déployées sur le cloud.',
                    Task2: 'Maintenir une grande base de code « legacy ».',
                    Task3: 'Codification full-stack des applications web en utilisant notamment Angular et C#.',
                    Task4: "Élaborer sur Figma des interfaces graphiques axées sur l'utilisabilité et l'expérience utilisateur.",
                    Task5: "Automatiser des tâches (CI/CD) dans Azure DevOps à des fins d'efficacité et d'assurance qualité.",
                },
            },
            JovacoTechLead: {
                Title: 'Lead Technique / Architecte',
                Dates: "Janvier 2024 - Aujourd'hui",
                Dept: 'Solutions infonuagiques',
                Tasks: {
                    Task1: 'Encadrer les développeurs en définissant les standards de programmation, les technologies et le déroulement des itérations.',
                    Task2: 'Définir et valider les besoins techniques.',
                    Task3: 'Participer au développement du produit, écrire du code, créer des tests automatisés et définir des normes de codage.',
                    Task4: 'Rechercher et améliorer les méthodes de travail.',
                },
            },
        },

        Projects: {
            Title: 'Quelques Projets',
            Project1: {
                Tag: 'Programmation Web',
                Name: 'Mustangs',
                Subname: 'Laravel',
                Infos: "Le site web a été conçu en utilisant le cadriciel Laravel dans le but de répondre aux besoins numériques de l'équipe sportive. De l'analyse des besoins jusqu'à la conception web, aucun détail n'a été négligé afin que l'équipe puisse tirer profit de la présence du site sur internet.",
            },
            Project2: {
                Tag: 'Apprentissage Machine',
                Name: 'Classification musicale',
                Subname: 'Design & Development',
                Infos: "Dans le cadre du cours d'apprentissage machine de l'école de technologie supérieure (GTI770), j'ai développé un système de classification musicale en utilisant le langage Python et les librairies Scikit-Learn et TensorFlow.",
            },
            Project3: {
                Tag: 'Conception logicielle',
                Name: 'Réservation de vols',
                Subname: 'Génie logiciel',
                Infos: "Le but de ce projet était de mettre en application l'ensemble des concepts du génie logiciel. De la conception par diagramme jusqu'au codage en utilisant les patrons de conceptions dans le langage JAVA.",
            },
            Project4: {
                Tag: 'Jeux vidéos',
                Name: 'Agents intelligents',
                Subname: 'intelligence artificielle',
                Infos: "En utilisant le moteur Unreal 4, il était question d'implémenter les systèmes intelligents des agents. Parmi ces systèmes, on compte la navigation, les systèmes décisionnels ou encore les animations.",
            },
            Project5: {
                Tag: 'Jeux vidéos',
                Name: 'Polarize',
                pSubname: 'C# et XNA',
                Infos: "Polarize est un jeu d'arène multijoueur codé en C# avec le moteur de jeu XNA. Le projet s'est déroulé en 2017 sur une période de quatre mois en équipe de trois dans le cadre de mon projet final d'études collégiales. L'objectif était de consolider les apprentissages en programmation, en mathématiques, en physique et d'étendre nos connaissances en développement de logiciels.",
            },
        },

        WorkTogether: {
            Title: 'Travaillons ensemble',
            Infos: "Êtes-vous à la recherche du candidat parfait pour votre prochain projet ambitieux ? Je suis toujours motivé à relever les défis et à participer à la création de nouvelles idées numériques. N'hésitez pas à me contacter pour discuter de la manière dont je peux vous aider à transformer votre idée en réalité.",
        },

        Location: {
            City: 'Montréal',
            Province: 'Québec',
        },
    },
};

export default fr;
