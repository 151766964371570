import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Project from '../Project';
import './projects.scss';
import { useTranslation } from 'react-i18next';

import project1png from '../../assets/images/projects/png/p-1.png';
import project2png from '../../assets/images/projects/png/p-2.png';
import project3png from '../../assets/images/projects/png/p-3.png';
import project4png from '../../assets/images/projects/png/p-4.png';
import project5png from '../../assets/images/projects/png/p-5.png';

import project1webp from '../../assets/images/projects/webp/p-1.webp';
import project2webp from '../../assets/images/projects/webp/p-2.webp';
import project3webp from '../../assets/images/projects/webp/p-3.webp';
import project4webp from '../../assets/images/projects/webp/p-4.webp';
import project5webp from '../../assets/images/projects/webp/p-5.webp';

const Projects = () => {
    const { t } = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
    };

    return (
        <section className="mh-featured-project" id="mh-featured-project">
            <div className="container">
                <div className="row section-separator">
                    <div className="section-title col-sm-12">
                        <h3>{t('Projects.Title')}</h3>
                    </div>
                    <div className="col-sm-12">
                        <div className="mh-single-project-slide-by-side">
                            <Slider {...settings}>
                                <Project
                                    png={project3png}
                                    webp={project3webp}
                                    tag={t('Projects.Project3.Tag')}
                                    name={t('Projects.Project3.Name')}
                                    subname={t('Projects.Project3.Subname')}
                                    infos={t('Projects.Project3.Infos')}
                                />
                                <Project
                                    png={project1png}
                                    webp={project1webp}
                                    tag={t('Projects.Project1.Tag')}
                                    name={t('Projects.Project1.Name')}
                                    subname={t('Projects.Project1.Subname')}
                                    infos={t('Projects.Project1.Infos')}
                                />
                                <Project
                                    png={project2png}
                                    webp={project2webp}
                                    tag={t('Projects.Project2.Tag')}
                                    name={t('Projects.Project2.Name')}
                                    subname={t('Projects.Project2.Subname')}
                                    infos={t('Projects.Project2.Infos')}
                                />
                                <Project
                                    png={project4png}
                                    webp={project4webp}
                                    tag={t('Projects.Project4.Tag')}
                                    name={t('Projects.Project4.Name')}
                                    subname={t('Projects.Project4.Subname')}
                                    infos={t('Projects.Project4.Infos')}
                                />
                                <Project
                                    png={project5png}
                                    webp={project5webp}
                                    tag={t('Projects.Project5.Tag')}
                                    name={t('Projects.Project5.Name')}
                                    subname={t('Projects.Project5.Subname')}
                                    infos={t('Projects.Project5.Infos')}
                                />
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Projects;
