export interface SkillProps {
    name: string;
    percentage: number;
}

const Skill = (props: SkillProps) => {
    return (
        <div className="candidatos">
            <div className="parcial">
                <div className="info">
                    <div className="nome">{props.name}</div>
                </div>
                <div className="progressBar">
                    <div className="percentagem" style={{ width: props.percentage + '%' }} />
                </div>
            </div>
        </div>
    );
};

export default Skill;
